var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-nowrap fill-height notes-list",class:{'hide-row-drag': !_vm.sidebarOpen}},[_c('NotesFilterBar'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditFormOpen),expression:"isEditFormOpen"}]},[_c('NoteEditForm')],1),_c('div',{staticClass:"flex-grow-1 overflow-hidden notes-grid-wrapper notes-table"},[_c('DataTable',{ref:"dataGrid",attrs:{"list":_vm.notes,"columnsList":_vm.columns,"options":_vm.options,"renderGroupCaption":_vm.createGroupItemsCaptions,"allowDragAndDrop":_vm.allowDragAndDrop,"whiteListBtns":[
        'add-resource-pop-up',
        'resource-type-selector',
        'resource-type-cate-selector',
        'resource-save-btn',
        'resource-title-editor-wrapper',
        'resource-error-pop-up',
        'v-overlay',
        'note-topic-form' ],"collapsedGroupsList":_vm.collapsedGroupsList,"itemSize":36},on:{"edit:enabled":_vm.handleEditEnabled,"row:added":_vm.handleRowAdd,"row:updated":_vm.handleRowUpdated,"rows:selected":_vm.handleRowsSelected,"row:clicked":_vm.handleRowsClicked,"filter":_vm.handleFilterSuccess,"list:processed":_vm.handleListProcessed,"group:toggled":_vm.handleGroupToggle,"key-pressed:delete":_vm.handleDeleteCall,"keydown":_vm.handleKeydown}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
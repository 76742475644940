

export default {
  data() {
    return {
      isToggleOpen: false,
    }
  },
  methods: {
    toggle() {
      this.isToggleOpen = !this.isToggleOpen;
    },
  },
  computed: {
    toggleData() {
      const value = this.isToggleOpen;
      // let icon = "mdi-menu-down";
      let label = "Open Description";

      if (value) {
        label = "Collapse Description";
      }
      return {
        iconClasses: {
          "e-icons e-gdiagonaldown": true,
          "e-icon-gdownarrow": value,
          "e-icon-grightarrow": !value,
        },
        label,
      };
    },
  }
}
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height note-edit-form"},[_c('FormLayout',{attrs:{"showForm":_vm.isNoteDataValid,"showDivider":false,"showLoading":_vm.loading,"editType":"Resource","isEditStarted":_vm.isEditStarted,"currItemIndex":_vm.currItemIndex,"totalItemCount":_vm.totalItemCount,"paginationRowClass":"pagination-row","paginationBtnIconColor":"#515151","noGutters":""},on:{"save":_vm.submitData,"close":_vm.closeForm,"edit-item:select":_vm.handleNoteEditType},scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_c('div',{staticClass:"task-title-actions-wrapper editable-area"},[_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c(VCol,{attrs:{"md":"auto","sm":"12"}},[_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"md":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"toggle-btn",attrs:{"icon":"","color":"#515151","small":""},on:{"click":_vm.toggle}},'v-btn',attrs,false),on),[_c('span',{class:_vm.toggleData.iconClasses})])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.toggleData.label)+" ")])])],1),_c(VCol,{attrs:{"md":"auto","sm":"12"}},[_c('div',{staticClass:"subject-selector-wrapper"},[_c('NoteFolderSelector',{attrs:{"placeholder":"Select Subject"},model:{value:(_vm.newNoteData.path),callback:function ($$v) {_vm.$set(_vm.newNoteData, "path", $$v)},expression:"newNoteData.path"}})],1)])],1)],1),_c(VCol,{attrs:{"md":"auto","sm":"12"}},[_c('div',[_c('ItemTypeMenu',{ref:"noteTypeSelector",attrs:{"type":"note","value":_vm.newNoteData.itemType},on:{"input":_vm.changeItemType}})],1)])],1)],1),_c('div',{staticClass:"mt-3 editable-area"},[_c('div',{staticClass:"note-title-input-wrapper"},[_c('DescrEditor',{directives:[{name:"show",rawName:"v-show",value:(_vm.isToggleOpen),expression:"isToggleOpen"}],attrs:{"value":_vm.newNoteData.title,"addSpaceOnEnter":true,"refSetter":function (refEl) { return _vm.setRef(refEl, 'titleInput'); }},on:{"input":_vm.handleTitleChange}})],1)]),_c('div',{staticClass:"mt-2 descr-editor-wrapper editable-area d-none"},[_c('DescrEditor',{attrs:{"value":_vm.descrData,"addSpaceOnEnter":true,"placeholder":_vm.editorPlaceholder,"refSetter":function (refEl) { return _vm.setRef(refEl, 'editor'); }},on:{"input":_vm.handleDescrDataChange}}),(_vm.areChangesSaved)?_c('div',{staticClass:"changes-saved-text"},[_vm._v(" Changes saved ")]):_vm._e()],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }